import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, ecomAppDefID} from '../constants';
import {addApplications, installMembersArea, registerMembersAreaApps} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';

export async function addPage(sdk: IEditorSdk, pageId: string, shouldNavigate: boolean) {
  const pageData = await sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    managingAppDefId: ecomAppDefID,
    componentType: sdk.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate,
    },
  });

  return sdk.document.pages.setState('', {
    state: {
      [pageId]: [{id: pageData.pageRef.id}],
    },
  });
}

export function addWidget(sdk: IEditorSdk, widgetId: string, addToAllPages: boolean): Promise<string> {
  return sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    componentType: sdk.tpa.TPAComponentType.Widget,
    widget: {
      widgetId,
      allPages: addToAllPages,
    },
  });
}

export async function addMembersArea(editorType: string, firstInstall: boolean, biData?: {origin?: string}) {
  await registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES);
  if (!firstInstall || editorType === 'ADI') {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await installMembersArea(options);
  await addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, options);
}

export async function setStateForPages(sdk: IEditorSdk) {
  const applicationPages = await sdk.document.pages.getApplicationPages('');
  applicationPages
    .filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID)
    .forEach((pageData) => {
      if (
        pageData.tpaPageId === PageMap.PRODUCT ||
        pageData.tpaPageId === PageMap.CART ||
        pageData.tpaPageId === PageMap.THANKYOU
      ) {
        sdk.document.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    });
}
export function openEcomBillingPage(editorSDK: IEditorSdk) {
  return editorSDK.editor.openBillingPage('token', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}
